<template>
  <div>
    <div class="actions flex jc-end mb-4">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'platform:termtemplate:button:delete'"
      >删除</a-button>
      <a-button
        type="primary"
        @click="newTerms"
        v-has="'platform:termtemplate:button:add'"
        icon="plus"
      >添加</a-button>
    </div>
    <a-table
      style="border-top:1px solid rgb(232,232,232)"
      v-has="'platform:termtemplate:page:view'"
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :customRow="clickTermsRow"
      :pagination="ipagination"
      :scroll="{x: 1000, y: 500 }"
      :loading="loading"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange ,columnWidth: 100}"
      @change="handleTableChange"
    >
    </a-table>
    <terms-modal
      :is-new="isNew"
      :platformId="parent.id"
      :show="showTermsModal"
      :id="curTempId"
      :templateList="departTermTemplates"
      @closed="tempModalClosed"
    ></terms-modal>
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import TermsModal from './terms-modal'
export default {
  components: {
    TermsModal,
  },
  mixins: [JeecgListMixin],
  props: {
    parent: {
      type: Object,
      default: {},
    },
    tab: {
      type: String,
      default: '1',
    },
  },
  watch: {
    tab(n) {
      // this.loadData();
    },
    departmentId() {
      this.getDepartTermTemplates()
    },
  },
  data() {
    return {
      isNew: false,
      showTermsModal: false,
      curTempId: 0,
      selectedRowKeys: [],
      clickTermsRow: (record, index) => ({
        on: {
          click: () => {
            this.showTermsModal = true
            this.isNew = false
            this.curTempId = record.id
          },
        },
      }),
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 100,
        },
        {
          title: '分类',
          width: 150,
          dataIndex: 'templateName',
        },
        {
          title: '说明',
          dataIndex: 'description',
        },
      ],
      departTermTemplates: [],
    }
  },
  methods: {
    tempModalClosed() {
      this.showTermsModal = false
      this.loadData()
    },
    newTerms() {
      this.isNew = true
      this.showTermsModal = true
    },
    del() {},
    getDepartTermTemplates() {
      if (this.departTermTemplates.length == 0 && this.parent.departmentId) {
        getAction('/terms/get_templates_by_depart', { id: this.parent.departmentId }).then((res) => {
          if (res.success) {
            this.departTermTemplates = Object.assign([], res.data)
          }
        })
      }
    },
  },
  created() {
    this.getDepartTermTemplates()
  },
  computed: {
    url: {
      get: function () {
        return {
          list: '/platform/get_terms_template?id=' + this.parent.id,
          deleteBatch: '/platform/delete_terms_template_batch/' + this.parent.id,
        }
      },
    },
    departmentId: {
      get: function () {
        return this.parent.departmentId
      },
    },
  },
}
</script>

<style lang='stylus'></style>